import MapScene from "@/core/game/scenes/MapScene";
import { injectable } from "inversify-props";
import { Scene, GameObjects } from "phaser";
import "reflect-metadata";
import IBuildTrainingGroundButtonCommand from "./IBuildTrainingGroundButtonCommand";

injectable();
export default class BuildTrainingGroundButtonCommand
  implements IBuildTrainingGroundButtonCommand
{
  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const buttonContainer = scene.add.container(
      parentContainer.x + 10,
      parentContainer.y + 5
    );
    scene.events.on("postupdate", () => {
      buttonContainer.setPosition(
        scene.cameras.main.scrollX + parentContainer.x + 15,
        scene.cameras.main.scrollY + parentContainer.y + 350
      );
    });
    const backRect = scene.add.rectangle(0, 0, 320, 50, 0xececec, 0.75);
    backRect.setOrigin(0, 0);
    backRect.setInteractive();
    backRect.on("pointerup", () => {
      (scene as Scene as MapScene).VM.IsBuildMenu = false;
      parentContainer.destroy();
      (scene as Scene as MapScene).VM.HandleBuildClick(
        parentContainer.getData("hex"),
        "TRAINING_GROUND"
      );
    });
    buttonContainer.add(backRect);
    const backText = scene.add.text(15, 5, "Build Training Ground", {
      fontSize: "30px",
      fontFamily: "Arial",
      color: "#000000",
    });
    buttonContainer.setDepth(100);
    buttonContainer.add(backText);
    parentContainer.add(buttonContainer);
    return buttonContainer;
  }
}
