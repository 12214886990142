import "reflect-metadata";
import { inject, injectable } from "inversify-props";
import ICampaignService from "./ICampaignService";
import ICampaign from "@/core/models/Campaign/ICampaign";
import IHttpService from "../HttpService/IHttpService";
import ICampaignFetchRequest from "@/core/models/Campaign/ICampaignFetchRequest";
import UIManager from "@/store/UIManager";
import Session from "@/store/Session";
import IAlertDataFactory from "@/core/factories/AlertDataFactory/IAlertDataFactory";
import { HttpCoreRoutes } from "@/core/constants/httpCoreRoutes";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";
import IPopulatedCampaign from "@/core/models/FunctionData/Campaign/IPopulatedCampaign";
import ILanguageService from "../LanguageService/ILanguageService";

@injectable()
export default class CampaignService implements ICampaignService {
  @inject("AlertDataFactory")
  private readonly _alertDataFactory!: IAlertDataFactory;

  @inject("LanguageService")
  private readonly _languageService!: ILanguageService;

  @inject("HttpService")
  private readonly _httpService!: IHttpService;

  public async AttackHexAsync(
    hexId: string,
    campaignId: string
  ): Promise<void> {
    UIManager.ToggleLoading();
    const d = await this._httpService
      .SetInstance("core")
      .SetToken(Session.Token)
      .RequestPost<any, { campaignId: string; hexId: string }>("/attackHex", {
        campaignId,
        hexId,
      });
    if (d.toString().includes("ERROR")) {
      UIManager.ToggleLoading();
      UIManager.AddAlert({
        icon: "mdi-crosshairs-off",
        color: "accent",
        message: this._languageService
          .SetLangauge(Session.Langauge)
          .GetTranslation(d as string),
      });
    } else {
      UIManager.ToggleLoading();
      UIManager.AddAlert({
        icon: "mdi-crosshairs",
        color: "secondary",
        message: this._languageService
          .SetLangauge(Session.Langauge)
          .GetTranslation(d as string),
      });
    }
  }

  public async BuildHexAsync(
    hexId: string,
    campaignId: string,
    struct: string
  ): Promise<boolean> {
    UIManager.ToggleLoading();
    const d = await this._httpService
      .SetInstance("core")
      .SetToken(Session.Token)
      .RequestPost<
        string,
        { hexId: string; campaignId: string; struct: string }
      >("/buildHex", { hexId, campaignId, struct });
    if (d.toString().includes("ERROR")) {
      UIManager.ToggleLoading();
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-alert-circle-outline",
          "accent",
          d.toString()
        )
      );
      return false;
    } else {
      UIManager.ToggleLoading();
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-hammer-wrench",
          "secondary",
          d.toString()
        )
      );
      return true;
    }
  }

  public async FetchMyCampaignByIdAsync(
    id: string
  ): Promise<IPopulatedCampaign> {
    UIManager.ToggleLoading();
    const d = await this._httpService
      .SetInstance("core")
      .SetToken(Session.Token)
      .RequestPost<IPopulatedCampaign, { campaignId: string }>(
        "/getMyCampaignById",
        {
          campaignId: id,
        }
      );
    if (d.toString().includes("ERROR")) {
      UIManager.ToggleLoading();
      UIManager.AddAlert({
        icon: "mdi-cloud-alert",
        color: "accent",
        message: d as string,
      });
      return {} as IPopulatedCampaign;
    } else {
      UIManager.ToggleLoading();
      return d as IPopulatedCampaign;
    }
  }

  public async FetchMyCampaignsAsync(
    page: number,
    limit: number
  ): Promise<Array<ICampaign>> {
    UIManager.ToggleLoading();
    const res = await this._httpService
      .SetInstance("core")
      .SetToken(Session.Token)
      .RequestPost<Array<ICampaign>, ICampaignFetchRequest>(
        HttpCoreRoutes.GET_MY_CAMPAIGNS,
        {
          page,
          limit,
        }
      );
    if (res.toString().includes("ERROR")) {
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-sync-alert",
          "accent",
          res as string
        )
      );
      UIManager.ToggleLoading();
      return [];
    }
    UIManager.ToggleLoading();
    return res as Array<ICampaign>;
  }

  public async IsMyCampaignAsync(campaignId: string): Promise<boolean> {
    UIManager.ToggleLoading();
    const res = await this._httpService.RequestPost<
      boolean,
      { campaignId: string }
    >("/isMyCampaign", { campaignId: campaignId });
    if (res.toString().includes("ERROR")) {
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-alert-userAlert",
          "accent",
          res as string
        )
      );
      UIManager.ToggleLoading();
      return false;
    }
    UIManager.ToggleLoading();
    return res as boolean;
  }
}
