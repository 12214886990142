export const EnglishTranslations = {
  EMAIL: "Email",
  PASS: "Password",
  ERROR_EMAIL_INCORRECT_FORMAT: "Your email is not a valid email address.",
  SERVER_ERROR_UNKNOWN: "Unknown error from server. Contact support.",
  ERROR_SERVER_LOGIN: "Email and/or Password incorrect.",
  TITLE_BASIC_LOGIN: "Log in",
  SUBMIT: "Submit",
  SIGN_UP: "Sign Up",
  NO_CAMPAIGNS: "Looks like you have no campaigns available.",
  TRY_AGAIN: "Try again",
  ERROR_SERVER_MUST_HAVE_BODY: "The request did not have a body.",
  ERROR_SERVER_UNAUTHORIZED: "You are not authorized",
  ERROR_CLIENT_FIREBASE_TOKEN_ERROR:
    "Error trying to recieve a token from firebase",
  ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN:
    "No token was recieved from firebase, no error",
  ERROR_SERVER_HEX_OWNED_BY_ATTACKER: "You already control this hex",
  ERROR_SERVER_ATTACKER_HAS_NO_NEIGHBOR:
    "Your hex does not neighbor the attacked hex",
  ERROR_SERVER_HEX_EXISTING_UNRESOLVED_ACTION:
    "This hex is locked until a pending game action is resolved.",
  ERROR_SERVER_HEX_NOT_ON_CAMPAIGN: "How are you even attacking this hex?",
  ERROR_SERVER_HEX_NOT_OWNED: "Hex not owned",
  ERROR_SERVER_USER_HAS_2_ATTACKS_THIS_TURN:
    "You alrady have two attack actions this turn.",
  ERROR_SERVER_USER_NOT_IN_CAMPAIGN: "How are you attacking this hex?",
  SERVER_SUCCESS_ATTACK: "Attack successful! Check your events page",
  SERVER_SUCCESS_BUILD: "Build success!",
  SERVER_ERROR_CANNOT_BUILD_NOT_YOUR_HEX: "You do not own this hex!",
};
