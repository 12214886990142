import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IMapSceneViewModel from "./IMapSceneViewModel";
import router from "@/router";
import IPopulatedCampaign from "@/core/models/FunctionData/Campaign/IPopulatedCampaign";
import ICampaignService from "@/core/services/CampaignService/ICampaignService";

@injectable()
export default class MapSceneViewModel implements IMapSceneViewModel {
  private readonly _campaignService: ICampaignService;
  private dragStart = { x: 0, y: 0 };
  private camStart = { x: 0, y: 0 };
  private isDragging = false;
  private isHold = false;
  private _isMenu = false;
  private _isGrid = true;
  private _isHexMenu = false;
  private _isBuildMenu = false;
  private _isShowColors = true;
  private _isGridChange = false;

  private campaign!: IPopulatedCampaign;

  public constructor(
    @inject("CampaignService") campaignService: ICampaignService
  ) {
    this._campaignService = campaignService;
  }

  public get Campaign(): IPopulatedCampaign {
    return this.campaign;
  }

  public get DragStart(): { x: number; y: number } {
    return this.dragStart;
  }

  public set DragStart(point: { x: number; y: number }) {
    this.dragStart = point;
  }

  public get IsBuildMenu(): boolean {
    return this._isBuildMenu;
  }

  public set IsBuildMenu(value: boolean) {
    this._isBuildMenu = value;
  }

  public get IsDrag(): boolean {
    return this.isDragging;
  }

  public SetDragging(flag: boolean): void {
    this.isDragging = flag;
  }

  public get IsChangeGrid(): boolean {
    return this._isGridChange;
  }

  public set IsChangeGrid(value: boolean) {
    this._isGridChange = value;
  }

  public get IsHold(): boolean {
    return this.isHold;
  }

  public set IsHold(value: boolean) {
    this.isHold = value;
  }

  public get IsMenu(): boolean {
    return this._isMenu;
  }

  public set IsMenu(value: boolean) {
    this._isMenu = value;
  }

  public get IsShowGrid(): boolean {
    return this._isGrid;
  }

  public set IsShowGrid(value: boolean) {
    this._isGrid = value;
  }

  public get IsShowColors(): boolean {
    return this._isShowColors;
  }

  public set IsShowColors(value: boolean) {
    this._isShowColors = value;
  }

  public get IsHexMenu(): boolean {
    return this._isHexMenu;
  }

  public set IsHexMenu(value: boolean) {
    this._isHexMenu = value;
  }

  public set CamStart(point: { x: number; y: number }) {
    this.camStart = point;
  }

  public get CamStart(): { x: number; y: number } {
    return this.camStart;
  }

  public async SetCampaign(): Promise<void> {
    this.campaign = await this._campaignService.FetchMyCampaignByIdAsync(
      router.currentRoute.value.params.id as string
    );
  }

  public HandleHistoryClick(hexId: string): void {
    router.push(`/campaign/${this.campaign?._id}/hex/${hexId}`);
  }

  public async HandleAttackClick(hexId: string): Promise<void> {
    await this._campaignService.AttackHexAsync(hexId, this.campaign._id || "");
  }

  public async HandleBuildClick(hexId: string, struct: string): Promise<void> {
    if (
      await this._campaignService.BuildHexAsync(
        hexId,
        this.campaign._id || "",
        struct
      )
    ) {
      const index = this.campaign.hexes.findIndex((e) => e._id === hexId);
      if (index !== -1) {
        this.campaign.hexes.at(index)!.structure = {
          structType: struct,
          cost: 20,
        };
        this._isGridChange = false;
      }
    }
  }
}
