import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "bg-background" }, {
    default: _withCtx(() => [
      (_ctx.session.Token)
        ? (_openBlock(), _createBlock(_component_v_app_bar, {
            key: 0,
            class: "bg-secondary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_app_bar_nav_icon, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
              }),
              _createVNode(_component_v_app_bar_title, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Realm Rank")
                ])),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.session.Token)
        ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
            key: 1,
            modelValue: _ctx.drawer,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
            location: _ctx.$vuetify.display.mobile ? 'bottom' : undefined,
            temporary: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, { items: ['Thing1', 'thing2', 'thing3'] })
            ]),
            _: 1
          }, 8, ["modelValue", "location"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_snackbar, {
        color: _ctx.uiManager.Alert.color || 'accent',
        "model-value": _ctx.uiManager.HasAlert,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.uiManager.ClearAlert())),
        timeout: "2000",
        timer: ""
      }, {
        default: _withCtx(() => [
          (_ctx.uiManager.Alert.icon)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 0,
                icon: _ctx.uiManager.Alert.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.uiManager.Alert.message), 1)
        ]),
        _: 1
      }, 8, ["color", "model-value"]),
      _createVNode(_component_v_overlay, {
        "model-value": _ctx.uiManager.Loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ml-16 mt-16 flex-row justify-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_progress_circular, {
                    color: "primary",
                    indeterminate: "",
                    size: "64"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model-value"]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}