export const AllTranslationKeys = {
  EMAIL: "EMAIL",
  PASS: "PASS",
  ERROR_EMAIL_INCORRECT_FORMAT: "ERROR_EMAIL_INCORRECT_FORMAT",
  ERROR_SERVER_LOGIN: "ERROR_SERVER_LOGIN",
  SERVER_ERROR_UNKNOWN: "SERVER_ERROR_UNKNOWN",
  TITLE_BASIC_LOGIN: "TITLE_BASIC_LOGIN",
  SUBMIT: "SUBMIT",
  SIGN_UP: "SIGN_UP",
  NO_CAMPAIGNS: "NO_CAMPAIGNS",
  TRY_AGAIN: "TRY_AGAIN",
  ERROR_SERVER_MUST_HAVE_BODY: "ERROR_SERVER_MUST_HAVE_BODY",
  ERROR_SERVER_UNAUTHORIZED: "ERROR_SERVER_UNAUTHORIZED",
  ERROR_CLIENT_FIREBASE_TOKEN_ERROR: "ERROR_CLIENT_FIREBASE_TOKEN_ERROR",
  ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN:
    "ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN",
  ERROR_SERVER_HEX_OWNED_BY_ATTACKER: "ERROR_SERVER_HEX_OWNED_BY_ATTACKER",
  ERROR_SERVER_ATTACKER_HAS_NO_NEIGHBOR:
    "ERROR_SERVER_ATTACKER_HAS_NO_NEIGHBOR",
  ERROR_SERVER_HEX_EXISTING_UNRESOLVED_ACTION:
    "ERROR_SERVER_HEX_EXISTING_UNRESOLVED_ACTION",
  ERROR_SERVER_HEX_NOT_ON_CAMPAIGN: "ERROR_SERVER_HEX_NOT_ON_CAMPAIGN",
  ERROR_SERVER_HEX_NOT_OWNED: "ERROR_SERVER_HEX_NOT_OWNED",
  ERROR_SERVER_USER_HAS_2_ATTACKS_THIS_TURN:
    "ERROR_SERVER_USER_HAS_2_ATTACKS_THIS_TURN",
  ERROR_SERVER_USER_NOT_IN_CAMPAIGN: "ERROR_SERVER_USER_NOT_IN_CAMPAIGN",
  SERVER_SUCCESS_ATTACK: "SERVER_SUCCESS_ATTACK",
  SERVER_SUCCESS_BUILD: "SERVER_SUCCESS_BUILD",
  SERVER_ERROR_CANNOT_BUILD_NOT_YOUR_HEX:
    "SERVER_ERROR_CANNOT_BUILD_NOT_YOUR_HEX",
};
