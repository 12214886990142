import { injectable } from "inversify-props";
import "reflect-metadata";
import IBuildButtonCommand from "./IBuildButtonCommand";
import Phaser, { Scene, GameObjects } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";

@injectable()
export default class BuildButtonCommand implements IBuildButtonCommand {
  public Execute<T extends Scene>(
    scene: T,
    parentContainer: GameObjects.Container
  ): GameObjects.Container {
    const buildButtonContanier = scene.add.container(12, 125);
    const shadow = scene.add.graphics();
    shadow.fillStyle(0x000000, 0.5);
    shadow.fillRoundedRect(5, 5, 103, 45, 10);
    const buildButtonRect = scene.add.graphics();
    buildButtonRect.fillStyle(0xa5cbf4, 1);
    buildButtonRect.fillRoundedRect(0, 0, 103, 45, 10);
    buildButtonRect.setInteractive(
      new Phaser.Geom.Rectangle(0, 0, 103, 45),
      Phaser.Geom.Rectangle.Contains
    );
    buildButtonRect.on("pointerup", () => {
      (scene as Scene as MapScene).VM.IsHexMenu = false;
      parentContainer.destroy();
      (scene as Scene as MapScene).VM.IsBuildMenu = true;
      (scene as Scene as MapScene).OpenBuildMenu(
        parentContainer.getData("hex")
      );
    });
    const buildText = scene.add.text(2, 2, "Build", {
      fontSize: "25px",
      fontFamily: "Arial",
      color: "#000000",
    });
    buildButtonContanier.add(shadow);
    buildButtonContanier.add(buildButtonRect);
    buildButtonContanier.add(buildText);
    buildButtonContanier.setDepth(100);
    parentContainer.add(buildButtonContanier);
    return buildButtonContanier;
  }
}
