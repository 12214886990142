import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IUserService from "./IUserService";
import IHttpService from "../HttpService/IHttpService";
import IUser from "@/core/models/User/IUser";
import IAlertDataFactory from "@/core/factories/AlertDataFactory/IAlertDataFactory";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";
import { HttpCoreRoutes } from "@/core/constants/httpCoreRoutes";
import Session from "@/store/Session";

@injectable()
export default class UserService implements IUserService {
  @inject("HttpService")
  private readonly _httpService!: IHttpService;

  @inject("AlertDataFactory")
  private readonly _alertDataFactory!: IAlertDataFactory;

  /**
   * @inheritdoc
   */
  public async UpdateMyUserField<T>(field: string, data: T): Promise<IUser> {
    const user = await this._httpService
      .SetInstance("core")
      .SetToken(Session.Token)
      .RequestPatch<IUser, object>(HttpCoreRoutes.PATCH_MY_USER_FIELDS, {
        [field]: data as T,
      });
    if (user.toString()?.includes("ERROR")) {
      this._alertDataFactory.CreateAlertData(
        "mdi-wrong-icon",
        "accent",
        AllTranslationKeys.ERROR_EMAIL_INCORRECT_FORMAT
      );
    }
    return user as IUser;
  }
}
