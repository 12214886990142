import { injectable } from "inversify-props";
import "reflect-metadata";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { FIREBASE_CONFIG } from "@/core/constants/firebaseConfig";
import IMessageService from "./IMessageService";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class MessageService implements IMessageService {
  private readonly _firebase: FirebaseApp;

  public constructor() {
    this._firebase = initializeApp(FIREBASE_CONFIG);
  }

  public async RequestPermission(): Promise<string> {
    try {
      const perm = await Notification?.requestPermission();
      if (perm !== "granted") {
        return AllTranslationKeys.ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN;
      }
      const messaging = getMessaging(this._firebase);

      const token = await getToken(messaging, {
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID,
      });
      return token || AllTranslationKeys.ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN;
    } catch (e) {
      return AllTranslationKeys.ERROR_CLIENT_FIREBASE_TOKEN_ERROR;
    }
  }
}
