import "reflect-metadata";
import { inject, injectable } from "inversify-props";
import IAuthService from "./IAuthService";
import IAuth from "@/core/models/Auth/IAuth";
import IBasicAuthResponse from "@/core/models/Auth/IBasicAuthResponse";
import IHttpService from "../HttpService/IHttpService";
import Auth from "@/core/models/Auth/Auth";
import Session from "@/store/Session";
import router from "@/router";
import UIManager from "@/store/UIManager";
import IAlertDataFactory from "@/core/factories/AlertDataFactory/IAlertDataFactory";
import IMessageService from "../MessageService/IMessageService";
import IUserService from "../UserService/IUserService";
import { HttpCoreRoutes } from "@/core/constants/httpCoreRoutes";

@injectable()
export default class AuthService implements IAuthService {
  @inject("AlertDataFactory")
  private readonly _alertDataFactory!: IAlertDataFactory;

  @inject("MessageService")
  private readonly _messageService!: IMessageService;

  @inject("UserService")
  private readonly _userService!: IUserService;

  @inject("HttpService")
  private readonly _httpService!: IHttpService;

  public async LoginWithEmailPass(auth: IAuth): Promise<void> {
    UIManager.ToggleLoading();
    const res = await this._httpService
      .SetInstance("core")
      .RequestPost<IBasicAuthResponse, Auth>(HttpCoreRoutes.LOGIN, auth);
    if (!(res as IBasicAuthResponse)?.Token) {
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-account-alert",
          "accent",
          res as string
        )
      );
    } else {
      Session.SetToken((res as IBasicAuthResponse).Token);
      const token = await this._messageService.RequestPermission();
      if (!token.includes("ERROR")) {
        this._userService.UpdateMyUserField<string>("deviceId", token);
      }
      router.push({ path: "/campaign-list" });
    }
    UIManager.ToggleLoading();
  }
}
