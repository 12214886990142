import { inject, injectable } from "inversify-props";
import "reflect-metadata";
import IHexGridBuilder from "./IHexGridBuilder";
import { GameObjects, Geom, Input, Scene } from "phaser";
import MapScene from "@/core/game/scenes/MapScene";
import IHex from "@/core/models/FunctionData/Campaign/IHex";
import IMenuFactory from "@/core/factories/MenuFactory/IMenuFactory";

@injectable()
export default class HexGridBuilder implements IHexGridBuilder {
  private readonly HEX_SIZE = 50;

  public Build(scene: Scene): Array<GameObjects.Polygon> {
    return (scene as MapScene).VM.Campaign.hexes.map((hex) => {
      (scene as MapScene)?.Hexes?.forEach((e) => e.destroy());
      const positionOnScreen = this.GetPositionForScene(
        hex.coordinates[0],
        hex.coordinates[1]
      );
      const pointsOnScreen = this.GetHexPoints(
        positionOnScreen.x,
        positionOnScreen.y
      );
      const hexToMap = scene.add.polygon(
        positionOnScreen.x,
        positionOnScreen.y,
        pointsOnScreen,
        (scene as Scene as MapScene).VM.IsShowColors
          ? this.GetColorOfHex(
              hex,
              (scene as Scene as MapScene).VM.Campaign.players
            )
          : undefined,
        0.3
      );
      hexToMap.setDepth(-1);
      (scene as MapScene).VM.IsShowGrid && hexToMap.setStrokeStyle(2, 0x000000);
      hexToMap.setData("dbHex", hex);

      hexToMap.setInteractive(
        new Phaser.Geom.Polygon(pointsOnScreen),
        Geom.Polygon.Contains
      );
      hexToMap.on("pointerup", (pointer: Input.Pointer) => {
        (scene as MapScene).VM.IsHold = false;
        if (
          !(scene as MapScene).VM.IsDrag &&
          !(scene as MapScene).VM.IsHexMenu
        ) {
          console.log(hex.coordinates);
          (scene as MapScene).VM.IsHexMenu = true;
          (scene as MapScene).OpenHexMenu(hex);
        }
      });

      return hexToMap;
    });
  }

  private GetPositionForScene(x: number, y: number): { x: number; y: number } {
    const horiz = (3 / 2) * this.HEX_SIZE * 0.5; // Horizontal distance between hex centers
    const vert = Math.sqrt(3) * this.HEX_SIZE * 0.5; // Vertical distance between hex centers

    const yOffset = x % 2 === 0 ? 0 : vert / 2;
    return {
      x: horiz * x,
      y: vert * y + yOffset,
    };
  }

  private GetHexPoints(x: number, y: number): number[] {
    const points: number[] = [];
    for (let i = 0; i < 6; i++) {
      const angle = (Math.PI / 180) * (60 * i);
      points.push(
        x + this.HEX_SIZE * Math.cos(angle),
        y + this.HEX_SIZE * Math.sin(angle)
      );
    }
    return points;
  }

  private GetColorOfHex(
    hex: IHex,
    owners: Array<{ userId: string; color: string }>
  ): number | undefined {
    // determine the owner of hex
    const owner = owners.find((o) => o.userId === hex.owner);
    // if owner return converted
    if (owner) {
      return parseInt(owner.color.replace("#", ""), 16);
    }
  }
}
