import "reflect-metadata";
import { injectable } from "inversify-props";
import IHttpService from "./IHttpService";
import axios, { AxiosInstance } from "axios";
import { baseURLs } from "@/core/constants/baseURLs";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";

@injectable()
export default class HttpService implements IHttpService {
  private readonly _instances: Map<string, AxiosInstance>;
  private _instance!: AxiosInstance | undefined;
  public constructor() {
    this._instances = new Map<string, AxiosInstance>();
    Object.keys(baseURLs).forEach((key: string) => {
      this._instances.set(
        key,
        axios.create({ baseURL: baseURLs[key as keyof { key: string }] })
      );
    });
    this._instance = this._instances.get("core")
      ? this._instances.get("core")
      : axios.create({ baseURL: "http://localhost:8888/.netlify/functions/" });
  }

  public SetInstance(instanceName: string): IHttpService {
    this._instance = this._instances.get(instanceName);
    return this;
  }

  public SetToken(token: string): IHttpService {
    this._instance
      ? (this._instance.defaults.headers["authorization"] = `Bearer ${token}`)
      : undefined;
    return this;
  }

  public async RequestDelete<T>(route: string): Promise<T> {
    return await this._instance
      ?.delete<T>(route)
      .then((data) => data.data)
      .catch((e) => {
        return (
          e?.response?.data?.error || AllTranslationKeys.SERVER_ERROR_UNKNOWN
        );
      });
  }

  public async RequestGet<T>(route: string): Promise<T | string> {
    return await this._instance
      ?.get<T>(route)
      .then((data) => data.data)
      .catch((e) => {
        return (
          e?.response?.data?.error || AllTranslationKeys.SERVER_ERROR_UNKNOWN
        );
      });
  }

  public async RequestPatch<Response, Request>(
    route: string,
    data: Request
  ): Promise<Response> {
    return await this._instance
      ?.put<Response>(route, data)
      .then((data) => data.data)
      .catch((e) => {
        return (
          e?.repsonse?.data?.error || AllTranslationKeys.SERVER_ERROR_UNKNOWN
        );
      });
  }

  public async RequestPost<Response, Request>(
    route: string,
    data: Request
  ): Promise<Response> {
    return await this._instance
      ?.post<Response>(route, data)
      .then((data) => data.data)
      .catch((e) => {
        return (
          e?.response?.data?.error || AllTranslationKeys.SERVER_ERROR_UNKNOWN
        );
      });
  }
}
